
var g_addToCart = new Array();

function addToCart(pid,pqty,pwarranty)
{
    g_addToCart.push(new Array(pid,pqty,pwarranty));
}

/*
function addToCartAjax(pid,pqty,pwarranty)
{
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $.ajax({
     type: 'POST',
     data: { id : pid, qty : pqty, warranty : pwarranty },
     url: 'ajax-add-item-to-cart',
     success: function(CartItemId){}
    });
}
*/

function ajaxAddToCart()
{
   $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
   });

    $.ajax({
        type: 'POST',
        dataType : "json",
        data: { 'qty':1, add : g_addToCart },
        url: 'ajax-add-items-to-cart',
        success: function(html){
            $("#cart-info-content").html(html[1]);
            $("#itemToCartModal").html(html[0]);
            $("#cart-list-content").html(html[2]);
            $("#itemToCartModal").modal();
        }
    });
}

$(function () {

    var product_id;

    $('#add-to-cart-list').on('hidden.bs.modal', function () {

        g_addToCart = new Array();

        //fő termék
        addToCart(product_id,$("#addqty").val(),$("input[name='warranty']").iCheck('update')[0].checked==true?1:0);

        $('input[name="add_related[]"].icheck').each(function () {
            if ($(this).iCheck('update')[0].checked) {
                addToCart($(this).val(), $("#related_qty_" + $(this).val()).val(), 0);
            }
        });

        ajaxAddToCart();

    });

    $('html').on('click', '.cart-btn', function () {



        $.ajaxSetup(
            {
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
        var id = $(this).attr('data-product-id');

        product_id = id;

        /*$.ajax(
         {
         type: 'POST',
         data: { id : id, qty : 1  },
         url: 'ajax-add-item-to-cart',
         success: function(CartItemId)
         {
         $("#cartItemId").val(CartItemId);
         }
         });*/


        $.ajax(
            {
                type: 'GET',
                url: '/termek/' + id,
                success: function (product) {
                    function getPercent() {
                        return ((product.gross_price - product.gross_discount_price) / (product.gross_price / 100));
                    }

                    var base_url = window.location.protocol + "//" + window.location.host + "/";

                    $relatedHtml = '';
                    $.each(product.related, function (key, value) {


                        var price = value.gross_price;
                        if (value.discount_active==1)
                        {
                            price = value.gross_discount_price;
                        }


                        $relatedHtml += '<div class="row">' +

                            '<div class="col-5"><img class="img-fluid" src="uploads/products/' + value.image + '" alt="' + value.name + '"></div>' +
                            '<div class="col-1 al">' +
                            '<input name="add_related[]" type="checkbox" class="icheck" value="' + value.id + '"/>' +
                            '</div>' +
                            '<div class="col-8">' + value.name +
                            '<br/><span id="kosarba_' + value.id + '" class="dn" style="color:green; font-weight:bold;" >Kosárba került</span></div>' +
                            '<div class="col-10"><label for="related_qty_' + value.id + '">Ha több darabot szeretnél, itt módosíthatod:</label><div class="col-12 np">' +
                            '<input value="1" data-ts-max="99" maxlength="2" type="text" class="form-control touchspin-vertical plusminus restrictInput integerOnly ar" id="related_qty_' + value.id + '"  name="related_qty_' + value.id + '">' +
                            '</div>' +
                            '<div class="col-12 ar cart-related-price">' + Math.round(price) + ',- Ft</div></div>' +
                            '' +
                            '<button onclick="return false;" class="btn btn-info"  data-toggle="_tooltip" title="' + value.description + '">Részletek</button>' +
                            '</div>';

                    });

                    $('.related-content').html($relatedHtml);


                    if ($relatedHtml != "") {
                        $(".related-content-container").show();

                        $('[data-toggle="tooltip"]').tooltip();

                    } else {
                        $(".related-content-container").hide();
                    }

                    $('.related-content input[type="checkbox"].icheck, .related-content input[type="radio"].icheck').iCheck(
                        {
                            checkboxClass: 'icheckbox_minimal',
                            radioClass: 'iradio_minimal',
                        });

                    $('input[name="add_related[]"].icheck').on('ifChecked', function(event){
                        $("#kosarba_"+$(this).val()).show();
                        //addToCart($(this).val(), $("#related_qty_"+$(this).val()).val(),0);
                    });

                    $('input[name="add_related[]"].icheck').on('ifUnchecked', function (event) {
                        $("#kosarba_"+$(this).val()).hide();
                        //addToCart($(this).val(),0,0);
                    });


                    $('.related-content .touchspin-vertical.plusminus').each(function () {
                        // setMinMax(this);
                        $(this).TouchSpin(
                            {
                                min: 1,
                                max: 99,
                                verticalbuttons: true,
                                verticalupclass: 'glyphicon glyphicon-plus',
                                verticaldownclass: 'glyphicon glyphicon-minus'
                            });
                    });

                    //$('.related-img').attr('src', 'public/uploads/products/' + product.related.image);
                    //$('.related-name').html(product.related.name);
                    //$('.related-price').html(product.related.net_price);
                    //$("input[name='related_id']").val(product.related.id);


                    $('#add-to-cart-title').text(product.name);
                    if(product.image!=''){
                        $('#add-to-cart-product-image').removeClass('hidden');
                        $('#add-to-cart-details').removeClass('col-24 col-sm-24');
                        $('#add-to-cart-details').addClass('col-19 col-sm-21');
                        $('#add-to-cart-product-image img').attr('src', 'uploads/products/'+product.image);
                        $('#add-to-cart-product-image img').attr('alt', product.name);
                    }else {
                        $('#add-to-cart-product-image').addClass('hidden');
                        $('#add-to-cart-details').removeClass('col-19 col-sm-21');
                        $('#add-to-cart-details').addClass('col-24 col-sm-24');
                    }
                    $("input[name='id']").val(product.id);
                    $('.gross_discount_price').text(Math.round(product.gross_discount_price, 0));
                    $('.gross_price').text(Math.round(product.gross_price, 0));
                    if (product.discount_active > 0) {
                        $('.discount_price').show();
                        $('.discount_percent').text(Math.round(getPercent(), 0));
                        $('.discount_amount').text(Math.round(product.gross_price - product.gross_discount_price, 0));
                    }
                    if (product.warranty_price > 0) {
                        $('.warranty-container').show();
                        $('.warranty-price').text(Math.round(product.warranty_price, 0));
                    }

                    $('#add-to-cart-list').modal();

                }
            });
    });
});